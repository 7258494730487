import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    documents: {
      termsAndConditions: {},
      privacyAndPolicy: {},
    },
    menu: {
      isHidden: false,
    },
    page: {
      isHomePage: false,
      pageSlug: '',
      contentNavigation: 'default',
      namePage: 'Wys En Scene',
      SEO: '',
    },
    modal: {
      isWelcome: false,
    },
    userAction: {
      watchNowClick: false,
    },
    ActionLogin: {
      isShowLoginModal: true,
    },
    slugTrailerGeoBlocking: '',
  },
  reducers: {
    setTermsAndConditions(state, action) {
      state.documents.termsAndConditions = action.payload;
    },
    setPrivacyAndPolicy(state, action) {
      state.documents.privacyAndPolicy = action.payload;
    },
    hideMenu(state, action) {
      state.menu.isHidden = true;
    },
    showMenu(state, action) {
      state.menu.isHidden = false;
    },
    setIsHomePage(state, action) {
      state.page.isHomePage = action.payload;
    },
    setIsWelcome(state, action) {
      state.modal.isWelcome = true;
    },
    setWatchNowClick(state, action) {
      state.userAction.watchNowClick = action.payload;
    },
    setTargerPlayer(state, action) {
      state.page.contentNavigation = action.payload;
    },
    setNamePage(state, action) {
      state.page.namePage = action.payload;
    },
    setSlugTrailerGeoBlocking(state, action) {
      state.slugTrailerGeoBlocking = action.payload;
    },
    setLoginModalShow(state, action) {
      state.ActionLogin.isShowLoginModal = action.payload;
    },
  },
});

export const {
  setTermsAndConditions,
  setPrivacyAndPolicy,
  hideMenu,
  showMenu,
  setIsHomePage,
  setIsWelcome,
  setWatchNowClick,
  setTargerPlayer,
  setNamePage,
  setLoginModalShow,
  setSlugTrailerGeoBlocking,
} = globalSlice.actions;

export default globalSlice.reducer;
export const privacyAndPolicySelector = state => state?.global?.documents?.privacyAndPolicy;
export const termsAndConditionsSelector = state => state?.global?.documents?.termsAndConditions;
