import FETCH from '../utils/fetch'
import * as APIString from '../constants/apiString'
import { store } from '../store/index'
import queryString from 'query-string'
import { getDeviceResolution, getAccessToken } from '~root/utils/utils'

export const createReview = (params = {}) => {
  const { account } = store.getState().auth
  if (!account) {
    return Promise.resolve(true)
  }

  let Authorization = ''
  if (account) {
    Authorization = account.access_token ? account.access_token : ''
  }
  const { content_id, method = 'GET', ...payload } = params

  const accountId = (account && account.profile && account.profile.id) || ''
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${content_id}/review/`
  return FETCH(
    path,
    'POST',
    {
      Authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(payload),
  )
}

export const getProgramShow = idContent => {
  const { account } = store.getState().auth
  let Authorization = ''
  if (account) {
    Authorization = account.access_token ? account.access_token : ''
  }
  const select = JSON.stringify({
    Content: ['id'],
  })
  let data = { select }
  const queryParams = queryString.stringify(data)
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${idContent}/program?${queryParams}`
  return FETCH(path, 'GET', {
    Authorization,
  })
}

export const getReviews = params => {
  const { account } = store.getState().auth

  let Authorization = ''
  if (account) {
    Authorization = account.access_token ? account.access_token : ''
  }
  const { content_id, method = 'GET', ...payload } = params

  const path = `/tenants/${
    APIString.TENANT_SLUG
  }/contents/${content_id}/reviews/?${queryString.stringify(params)}`
  return FETCH(path, 'GET', {
    Authorization,
    'Content-Type': 'application/json',
  })
}

export function updateReview(params) {
  const { id, review_id, rating, comment } = params
  let authorization = getAccessToken()
  const path = `/tenants/${APIString.TENANT_SLUG}/reviews/${
    id || review_id
  }/detail`
  return FETCH(
    path,
    'PUT',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  )
}
