import { componentLoader } from '~root/app/functions/lazy-retry';
import { tenantEnvConfig } from '~constants/tenantConfig/tenantEnvConfig';
import { getConfigByKey } from '~features/tenantConfig/services';
import Loadable from 'react-loadable';
import React from 'react';
import { routePageNames } from '~core/utils/route/routeName';
import { PrivacyPolicyPage } from '~pages/privacy-policy/PrivacyPolicyPage';
import { TermOfUsePage } from '~pages/term-of-use/TermOfUsePage';
const HomePage = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ './features/homepage/views/homePage'),
  loading: () => <div>loading...</div>,
  modules: ['homepage'],
});
const TermsOfUse = componentLoader(() => import('./features/terms-of-use/views'));
const Confirmation = componentLoader(() => import('./features/confirmation/views/index'));
const LoginSso = componentLoader(() => import('./features/auth/pages/loginSSO/views/loginSSOPage'));
const DetailPage = componentLoader(() => import('./features/detailpage/views/playerPage'));
const LandingPage = componentLoader(() => import('./features/landingpage/views'));
const LivePage = componentLoader(() => import('./features/live/views/livePage'));
const RibbonPage = componentLoader(() => import('./features/ribbon/views/ribbonPage'));
const MyList = componentLoader(() => import('./features/myList/views/myList'));
const SearchPage = componentLoader(() => import('./features/search/views/searchPage'));
const Profile = componentLoader(() => import('./features/profile/views/profile'));
const Box = componentLoader(() => import('./features/box/views/boxPage'));
const ChangePass = componentLoader(() => import('./features/auth/components/changePass'));
const UpgradeSubcription = componentLoader(() => import('./features/upgradeSubscription/views'));
const BlockCountry = componentLoader(() => import('./features/blockCountry/views/BlockCountry'));
const BoxSvod = componentLoader(() =>
  import('./features/box/pages/subscriptionPlanPage/views/boxSubscriptionSvod'),
);
const YoutubePage = componentLoader(() => import('./features/youtubePage/views/index'));
const CategoryPage = componentLoader(() => import('./features/categoryPage/views/index'));
const Metadata = componentLoader(() => import('./features/metadata/views/index'));
const MoreLikeThis = componentLoader(() =>
  import('./features/detailpage/videoComponents/escondido/moreLikeThis'),
);

const InstallApp = componentLoader(() => import('./features/install-app/views'));
const PageNotFound = componentLoader(() => import('~root/pages/404'));
const VodListPage = componentLoader(() =>
  import('~features/vodListPage/projectw/views/VodListPage'),
);

const MyPage = componentLoader(() => import('~features/myPage/MyPage'));

const locales = tenantEnvConfig.locales || getConfigByKey('locales') || ['vi', 'en', 'ko', 'es'];
let stringLanguage = locales && locales.join('|');

// const baseRouteUrl = `/:lng(${stringLanguage})`
const baseRouteUrl = `/:lg`;
const routes = [
  {
    path: `${baseRouteUrl}`,
    exact: true,
    main: () => HomePage,
  },
  {
    path: `${baseRouteUrl}/account/confirmation`,
    exact: true,
    main: () => Confirmation,
  },
  {
    path: `${baseRouteUrl}/auth/login/sso`,
    exact: true,
    main: () => LoginSso,
  },
  // {
  //   path: `${baseRouteUrl}/terms-of-use`,
  //   exact: true,
  //   main: () => TermsOfUse,
  // },
  {
    path: `${baseRouteUrl}/detail/:slug`,
    exact: true,
    main: () => DetailPage,
  },
  {
    path: `${baseRouteUrl}/landing/:slug`,
    exact: true,
    main: () => LandingPage,
  },
  // {
  //   path: `${baseRouteUrl}/live/:slug`,
  //   exact: true,
  //   main: () => LivePage,
  // },
  {
    path: `${baseRouteUrl}/ribbon/:slug`,
    exact: true,
    main: () => RibbonPage,
  },
  {
    path: `${baseRouteUrl}/favorite`,
    exact: true,
    main: () => MyList,
  },
  {
    path: `${baseRouteUrl}/search`,
    exact: true,
    main: () => SearchPage,
  },
  {
    path: `${baseRouteUrl}/search/:keyword`,
    exact: true,
    main: () => SearchPage,
  },
  {
    path: `${baseRouteUrl}/search/:keyword/page/:page`,
    exact: true,
    main: () => SearchPage,
  },
  {
    path: `${baseRouteUrl}/profile`,
    main: () => Profile,
  },
  {
    path: `${baseRouteUrl}/help`,
    exact: true,
    main: () => Profile,
  },
  {
    switch: true,
    exact: true,
    router: [
      {
        path: `${baseRouteUrl}/faq/:slugBlockAds`,
      },
      {
        path: `${baseRouteUrl}/faq`,
      },
    ],
    main: () => Profile,
  },
  {
    path: `${baseRouteUrl}/:tenants(page|live)/:slug`,
    exact: true,
    main: () => HomePage,
  },
  {
    path: `${baseRouteUrl}/category/:slug`,
    exact: true,
    main: () => CategoryPage,
  },
  // {
  //   path: `${baseRouteUrl}/blocks`,
  //   exact: true,
  //   main: () => UpgradeSubcription
  // },
  {
    path: `${baseRouteUrl}/password/change`,
    exact: true,
    main: () => ChangePass,
  },
  {
    path: `${baseRouteUrl}/block`,
    exact: true,
    main: () => BlockCountry,
  },
  // {
  //   path: `${baseRouteUrl}/box/:plant`,
  //   exact: true,
  //   main: () => Box,
  // },
  {
    path: `${baseRouteUrl}/install-app`,
    exact: true,
    main: () => InstallApp,
  },
  {
    switch: true,
    exact: true,
    router: [
      {
        path: `${baseRouteUrl}/box/offers/svod`,
        flag: true,
        main: () => BoxSvod,
        data: {
          svod: true,
        },
      },
      {
        path: `${baseRouteUrl}/box/:plant`,
      },
      {
        path: `${baseRouteUrl}/box`,
      },
    ],
    main: () => Box,
  },
  {
    path: `${baseRouteUrl}/youtube/:slug`,
    exact: true,
    main: () => YoutubePage,
  },
  {
    path: `${baseRouteUrl}/youtube/:slug/search`,
    exact: false,
    props: { search: true },
    main: () => YoutubePage,
  },
  {
    path: `${baseRouteUrl}/detail/:slug/preview`,
    exact: true,
    main: () => MoreLikeThis,
  },
  {
    path: `${baseRouteUrl}/:metadataName(genres|nationalities|directors|casts|producers)/:slug`,
    exact: true,
    main: () => Metadata,
  },

  {
    path: `${baseRouteUrl}/vod-list-page/`,
    exact: true,
    main: () => VodListPage,
  },
  {
    path: `${baseRouteUrl}/my-page/`,
    exact: true,
    main: () => MyPage,
  },
  {
    path: `${baseRouteUrl}${routePageNames.privacyPolicy}`,
    exact: true,
    main: () => PrivacyPolicyPage,
  },
  {
    path: `${baseRouteUrl}${routePageNames.termsOfUse}`,
    exact: true,
    main: () => TermOfUsePage,
  },
  {
    exact: true,
    path: `${baseRouteUrl}/404`,
    main: () => PageNotFound,
  },
];
export default routes;
