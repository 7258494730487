import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setPrivacyAndPolicy } from '~components/global/globalServices';
import { privacyAndPolicySelector } from '~components/global/globalSlice';

const PrivacyPolicyPageStyled = styled.div`
  background-color: white;
  display: grid;
  .html-content {
    @media (min-width: 992px) {
      margin-inline: auto;
      width: 60%;
    }
  }
`;

export const PrivacyPolicyPage = () => {
  const privacyPolicy = useSelector(privacyAndPolicySelector);

  const dispatch = useDispatch();

  useEffect(() => {
    setPrivacyAndPolicy()(dispatch);
  }, []);

  return (
    <PrivacyPolicyPageStyled className="font-open-san bg-[#fff]">
      <div
        className="mx-auto w-full md:w-[60%] html-content"
        dangerouslySetInnerHTML={{
          __html: privacyPolicy?.content,
        }}
      ></div>
    </PrivacyPolicyPageStyled>
  );
};

export default PrivacyPolicyPage;
