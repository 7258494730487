import React, { Suspense, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '~root/routes';
import OverlayLoading from '~components/loading/overlayLoading';
import * as method from '../functions/method-login';
import FooterSeo from '~components/metaTags/component/footerSeo';
import Loadable from 'react-loadable';
import MetaTagSeo from '~components/metaTags/views';
import { isNode } from '~utils/ssr';
import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';
import FloatAds from '~components/adsBanner/FloatAds';

const Header = Loadable({
  loader: () => import(/* webpackChunkName: "header" */ '~components/header/header'),
  loading: () => <div>loading...</div>,
  modules: ['header'],
});

const Footer = Loadable({
  loader: () => import(/* webpackChunkName: "footer" */ '~features/footer/footer'),
  loading: () => <div>loading page...</div>,
  modules: ['footer'],
});

const DefaultRouter = React.memo(props => {
  const { component } = props;
  const isLoading = component.props.isLoading;
  const reload = () => window.location.reload();

  return (
    <React.Fragment>
      <Header showLoginAction={() => method._showLoginModal(component)} />
      <main>
        <Routers component={component} />
      </main>
      <FooterSeo />
      <Footer block={['detail', 'block']} />
      {getConfigByKey('ui_theme') === TENANT_NAME_ENUM.ODV && <FloatAds />}
    </React.Fragment>
  );
});

const Routers = ({ component }) => {
  if (isNode()) {
    return routes.map((route, index) => {
      const Component = route.main();
      if (route.main().preload) {
        return <Component />;
      }
    });
  }
  return (
    <Suspense fallback={<OverlayLoading />}>
      {routes.map((route, index) => {
        const Component = route.main();
        if (route.switch) {
          return (
            <Switch key={index}>
              {route.router.map((item, indexs) => {
                let SwitchComponent = item.flag ? item.main() : Component;
                return (
                  <Route key={indexs} path={item.path} exact={route.exact}>
                    <SwitchComponent
                      showLoginAction={param => method._showLoginModal(component, param)}
                      showForgotPassAction={() => method._showForgotPassModal(component)}
                      {...(item.data || {})}
                    />
                    <MetaTagSeo />
                  </Route>
                );
              })}
            </Switch>
          );
        }
        return (
          <Route key={index} path={route.path} exact={route.exact} state={route.state}>
            <Component
              showLoginAction={param => method._showLoginModal(component, param)}
              showForgotPassAction={() => method._showForgotPassModal(component)}
              {...(route.props || {})}
            />
            <MetaTagSeo />
          </Route>
        );
      })}
    </Suspense>
  );
};

export default DefaultRouter;
