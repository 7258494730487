import * as actions from './actions';
import * as appAPIs from '../../api/appAPIs';

import pushAnalytics from './../../services/analytics/Analytics';
import * as surverApi from '../../api/survey';
import * as accountApi from '../../api/account';
import * as actionsAuth from './../auth/actions';
import * as auth from '~api/auth';
import * as review from '~api/review';

export {
  changePassword,
  getProfile,
  changeProfile,
  getPaymentPlan,
  getClientSecret,
  refresh_stb_auth_code,
  paymentPlan,
  cancel_subscription,
  get_actived_subscription_plan,
  get_subscription_history,
  changeEmail,
  resend_email,
  supports,
  getAccountInfo,
  getSurveys,
  postSurveys,
  postCouponCancel,
  subscriptionHistoryV2,
  getPaypalMethod,
  checkPassword_Current,
  updateProfile_Request,
  updateAvatar_Request,
  removeAccount_Request,
  uploadImage,
  getListReview,
  removeMyReview,
  updateReview,
  postRequestRefund,
  getRequestRefund,
};

function getProfile() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getProfile()
        .then(response => {
          dispatch(actions.getProfile(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function changePassword(old_password, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .changePassword(old_password, password)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function changeProfile(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .updateProfile(params)
        .then(response => {
          resolve(response);
          dispatch(actions.changeProfileSuccess(response));
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getPaymentPlan() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_payment_plan()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getClientSecret(plan_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getClientSecret(plan_id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function refresh_stb_auth_code() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .refresh_stb_auth_code()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function paymentPlan(stripe_plan_id, stripe_payment_method_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .paymentPlan(stripe_plan_id, stripe_payment_method_id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function get_actived_subscription_plan() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_actived_subscription_plan()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function cancel_subscription() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      pushAnalytics('click', { content_type: 'button', item_name: 'Yes' });
      appAPIs
        .cancel_subscription_plan()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function get_subscription_history() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .subscription_history()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function changeEmail(old_email, new_email) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .change_email(old_email, new_email)
        .then(response => {
          dispatch(actions.changeEmail(new_email));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function resend_email(type) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .resend_email(type)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function supports(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .supports(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getAccountInfo() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getAccountInfo()
        .then(response => {
          dispatch(actions.updateHasSubscription(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSurveys() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      surverApi
        .getSurveys()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
function postSurveys(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      surverApi
        .postSurveys(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postCouponCancel(params = {}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      accountApi
        .postCouponCancel(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function subscriptionHistoryV2() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .subscriptionHistoryV2()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getPaypalMethod() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      accountApi
        .getPaypalMethod()
        .then(response => {
          dispatch(actionsAuth.updateAccountPaymentMethodPaypal(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function checkPassword_Current(password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        ._checkCurrentPassword(password)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateProfile_Request(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .updateProfileRequest(params)
        .then(response => {
          resolve(response);
          setTimeout(() => {
            dispatch(actions.changeProfileSuccess(response));
          }, 2000);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateAvatar_Request(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .updateAvatarRequest(params)
        .then(response => {
          resolve(response);
          setTimeout(() => {
            dispatch(actions.changeProfileSuccess(response));
          }, 2000);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function removeAccount_Request() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .removeAccount()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function uploadImage(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .uploadImage(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
export function getPaidContent(params = {}) {
  return new Promise((resolve, reject) => {
    auth
      .getPaidContent(params)
      .then(response => {
        resolve(response);
      })
      .catch(errors => {
        reject(errors);
      });
  });
}

export function createReview(params = {}) {
  return new Promise((resolve, reject) => {
    review
      .createReview(params)
      .then(response => {
        resolve(response);
      })
      .catch(errors => {
        reject(errors);
      });
  });
}

function getListReview(params = {}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getListReview(params)
        .then(response => {
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function removeMyReview(review_id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .removeMyReview(review_id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateReview(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      review
        .updateReview(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRequestRefund(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getRequestRefund(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postRequestRefund(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .postRequestRefund(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
