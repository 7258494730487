import * as actions from './globalSlice';
import * as documentsApis from '~api/documents';
import * as documentConstants from '~constants/documents';

export const setTermsAndConditions = params => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      documentsApis
        .getDocumentByType({ type: documentConstants.TERMS_AND_CONDITIONS })
        .then(res => {
          dispatch(actions.setTermsAndConditions(res));
          resolve(res);
        });
    });
  };
};

export const setPrivacyAndPolicy = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      documentsApis.getDocumentByType({ type: documentConstants.PRIVACY_AND_POLICY }).then(res => {
        dispatch(actions.setPrivacyAndPolicy(res));
        resolve(res);
      });
    });
  };
};

export const hideMenu = () => {
  return dispatch => {
    dispatch(actions.hideMenu());
  };
};

export const showMenu = () => {
  return dispatch => {
    dispatch(actions.showMenu());
  };
};

export const setIsHomePage = payload => {
  return dispatch => {
    dispatch(actions.setIsHomePage(payload));
  };
};

export const setIsWelcome = () => {
  return dispatch => {
    dispatch(actions.setIsWelcome());
  };
};

export const setWatchNowClick = () => {
  return dispatch => {
    dispatch(actions.setWatchNowClick());
  };
};

export const setNavigationContents = payload => {
  return dispatch => {
    dispatch(actions.setTargerPlayer(payload));
  };
};
export const setNamePage = () => {
  return dispatch => {
    dispatch(actions.setNamePage());
  };
};

export const setLoginModalShow = () => {
  return dispatch => {
    dispatch(actions.setLoginModalShow());
  };
};
export const setSlugTrailerGeoBlocking = value => {
  return dispatch => {
    dispatch(actions.setSlugTrailerGeoBlocking(value));
  };
};
