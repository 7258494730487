import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setTermsAndConditions } from '~components/global/globalServices';
import { termsAndConditionsSelector } from '~components/global/globalSlice';

const TermOfUsePageStyled = styled.div`
  background-color: white;
  display: grid;
  .html-content {
    @media (min-width: 992px) {
      margin-inline: auto;
      width: 60%;
    }
  }
`;
export const TermOfUsePage = () => {
  const termOfUse = useSelector(termsAndConditionsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    setTermsAndConditions()(dispatch);
  }, []);
  return (
    <TermOfUsePageStyled className="bg-[#fff] font-open-sans wrap">
      <div
        className="mx-auto w-full md:w-[60%] html-content"
        dangerouslySetInnerHTML={{
          __html: termOfUse?.content,
        }}
      ></div>
    </TermOfUsePageStyled>
  );
};

export default TermOfUsePage;
